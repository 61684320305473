import { createTheme } from '@mui/material/styles';

const rootStyles = getComputedStyle(document.documentElement);

const theme = createTheme({
  palette: {
    primary: {
      main: rootStyles.getPropertyValue('--wt-green')
    },
    secondary: {
      main: '#ff00d5'
    },
    error: {
      main: rootStyles.getPropertyValue('--wt-red')
    },
    text: {
      primary: rootStyles.getPropertyValue('--white')
    },
    mode: 'dark'
  }
});

export default theme;
