import { useState } from 'react';
import { TeamData, VALID_SPORTS } from '../../models';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import {
  BetsData,
  GamePeriods,
  GENERAL_GAME_PERIODS,
  SUB_PAGE
} from '../../models/common';
import { mapSportToApiKey, mapSubPageAndTimeDivisionToApiKey } from '.';
import { GamePeriodPicker, TeamHistoricalTable } from '../../components/common';

interface TeamHistoricalResultsProps {
  allTeams: TeamData[];
  getBetsByTeamId: UseQuery<
    QueryDefinition<
      {
        teamId: string;
        marketKeys: string[];
        sportKey: string;
        league: string;
      },
      any,
      never,
      BetsData[],
      string
    >
  >;
  sport: VALID_SPORTS;
}

export const TeamHistoricalResults = (props: TeamHistoricalResultsProps) => {
  const { allTeams, getBetsByTeamId, sport } = props;
  const [activeTeam, setActiveTeam] = useState<TeamData | undefined>();

  const [selectedTimePeriod, setSelectedTimePeriod] = useState<GamePeriods>(
    GENERAL_GAME_PERIODS.FULL_TIME
  );
  const moneyLineMarketKey = mapSubPageAndTimeDivisionToApiKey(
    SUB_PAGE.MONEYLINE,
    selectedTimePeriod
  );
  const spreadMarketKey = mapSubPageAndTimeDivisionToApiKey(
    SUB_PAGE.SPREAD,
    selectedTimePeriod
  );
  const totalMarketKey = mapSubPageAndTimeDivisionToApiKey(
    SUB_PAGE.TOTAL,
    selectedTimePeriod
  );

  const handleSelect = (event: SelectChangeEvent) => {
    setActiveTeam(allTeams.find(({ id }) => id === event.target.value));
  };

  const { data: gamesData, isFetching: isLoading } = getBetsByTeamId({
    sportKey: mapSportToApiKey(sport).sportKey,
    league: mapSportToApiKey(sport).league,
    teamId: activeTeam ? activeTeam.id : allTeams[0].id,
    marketKeys: [moneyLineMarketKey, spreadMarketKey, totalMarketKey]
  });

  return (
    <>
      <GamePeriodPicker
        selectedTimePeriod={selectedTimePeriod}
        setSelectedTimePeriod={setSelectedTimePeriod}
        sport={sport}
      />
      <div
        style={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          margin: '16px',
          width: '100%'
        }}
      >
        <FormControl style={{ marginBottom: '16px', minWidth: '20%' }}>
          <InputLabel>Team</InputLabel>
          <Select
            value={activeTeam?.id || ''}
            label="Team"
            onChange={handleSelect}
          >
            {allTeams
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {activeTeam ? (
          !isLoading && gamesData ? (
            <TeamHistoricalTable
              allTeams={allTeams}
              gamesData={gamesData}
              marketKeys={{
                moneyLineMarketKey,
                spreadMarketKey,
                totalMarketKey
              }}
              team={activeTeam}
            />
          ) : (
            <CircularProgress />
          )
        ) : (
          <div>Please select a team.</div>
        )}
      </div>
    </>
  );
};
