import { DataGrid, GridColDef } from '@mui/x-data-grid';
import './index.scss';
import { BetsData } from '../../../models/common';
import { useEffect, useState } from 'react';
import { TeamData } from '../../../models';
import { toDayString, toMonthDayString } from '../../../utils/helper';

interface TeamHistoricalTableProps {
  allTeams: TeamData[];
  gamesData: BetsData[];
  marketKeys: {
    moneyLineMarketKey: string;
    spreadMarketKey: string;
    totalMarketKey: string;
  };
  team: TeamData;
}

type TableData = {
  id: string;
  date: Date;
  day: Date;
  venue: string;
  opponent: TeamData | undefined;
  score: string;
  spread: string;
  totals: string;
};

export const TeamHistoricalTable = (props: TeamHistoricalTableProps) => {
  const { allTeams, gamesData, marketKeys, team } = props;
  const { moneyLineMarketKey, spreadMarketKey, totalMarketKey } = marketKeys;
  const [tableData, setTableData] = useState<TableData[]>([]);

  const getTeamsData = (game: BetsData, ourTeam: TeamData) =>
    game.teams.away_team.id === ourTeam.id
      ? {
          ourTeamData: game.teams.away_team,
          theirTeamData: game.teams.home_team
        }
      : {
          ourTeamData: game.teams.home_team,
          theirTeamData: game.teams.away_team
        };

  useEffect(() => {
    const tempTableData: TableData[] = [];

    gamesData.forEach((game) => {
      const { ourTeamData, theirTeamData } = getTeamsData(game, team);

      ourTeamData?.result &&
        ourTeamData.result !== 'postponed' &&
        tempTableData.push({
          id: `${game.startTime}${theirTeamData?.id}`,
          date: new Date(game.startTime),
          day: new Date(game.startTime),
          venue: ourTeamData.venue === 'Home' ? 'Home' : 'Away',
          opponent: allTeams.find(
            (teamData) => teamData.id === theirTeamData?.id
          ),
          score:
            ourTeamData.venue === 'Home'
              ? `${ourTeamData.bets.bet[moneyLineMarketKey]?.result[0]} ${theirTeamData.bets.bet[moneyLineMarketKey]?.value}-${ourTeamData.bets.bet[moneyLineMarketKey]?.value}`
              : `${ourTeamData.bets.bet[moneyLineMarketKey]?.result[0]} ${ourTeamData.bets.bet[moneyLineMarketKey]?.value}-${theirTeamData.bets.bet[moneyLineMarketKey]?.value}`,
          spread: `${ourTeamData.bets.bet[spreadMarketKey]?.result[0]} ${ourTeamData.bets.bet[spreadMarketKey]?.value}`,
          totals: `${ourTeamData.bets.bet[totalMarketKey]?.result === 'Win' ? 'O' : ourTeamData.bets.bet[totalMarketKey]?.result === 'Loss' ? 'U' : 'P'} ${ourTeamData.bets.bet[totalMarketKey]?.value}`
        });
    });

    setTableData(tempTableData);
  }, [gamesData]);

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      renderCell: ({ value }) => toMonthDayString(value)
    },
    {
      field: 'day',
      headerName: 'Day',
      renderCell: ({ value }) => toDayString(value),
      sortComparator: (v1, v2) => {
        const day1 = v1.getDay();
        const day2 = v2.getDay();

        return day1 === day2 ? 0 : day1 < day2 ? -1 : 1;
      }
    },
    {
      field: 'venue',
      headerName: 'Home/Away',
      minWidth: 145
    },
    {
      field: 'opponent',
      headerName: 'Versus',
      minWidth: 330,
      flex: 2,
      renderCell: ({ row, value }) => (
        <div>
          <img
            src={value.logoPath}
            alt={`${value.id} logo`}
            style={{ height: '52px', width: 'auto', marginRight: '5px' }}
          />
          {row.venue === 'Away' && '@ '}
          {value.name}
        </div>
      ),
      sortComparator: (v1, v2) =>
        v1.name.toLowerCase().localeCompare(v2.name.toLowerCase()),
      filterable: false
    },
    {
      field: 'score',
      headerName: 'Score',
      minWidth: 145,
      flex: 1
    },
    {
      field: 'spread',
      headerName: 'ATS',
      minWidth: 145,
      flex: 1
    },
    {
      field: 'totals',
      headerName: 'Totals',
      minWidth: 145
    }
  ];

  return (
    <div className="container">
      <DataGrid rows={tableData} columns={columns} autoHeight hideFooter />
    </div>
  );
};
