export * from './LineMovement.model';
export * from './MatchUp.model';
export * from './PublicData.model';
export * from './Streaks.model';
export * from './HistoricalResults.model';

export enum SECONDARY_NAV {
  STREAKS = 'streaks',
  LINE_MOVEMENT = 'line-movement',
  PUBLIC = 'public',
  MATCH_UP = 'match-up',
  HISTORICAL_RESULTS = 'old-historical-results',
  HIT_RATE = 'hit-rate',
  TEAM_HISTORICAL_RESULTS = 'historical-results'
}

export enum SUB_PAGE {
  ALL = 'All',
  MONEYLINE = 'Moneyline',
  SPREAD = 'Spread',
  TOTAL = 'Total'
}

export type GamePeriods = GENERAL_GAME_PERIODS | HOCKEY_GAME_PERIODS;

export enum GENERAL_GAME_PERIODS {
  FULL_TIME = 'Full Time',
  FIRST_HALF = '1st Half',
  FIRST_QUARTER = '1Q',
  SECOND_QUARTER = '2Q',
  THIRD_QUARTER = '3Q',
  FOURTH_QUARTER = '4Q'
}

export enum HOCKEY_GAME_PERIODS {
  FULL_TIME = 'Full Time',
  FIRST_PERIOD = '1st',
  SECOND_PERIOD = '2nd',
  THIRD_PERIOD = '3rd'
}

export enum RESULT {
  WIN = 'Win',
  LOSS = 'Loss',
  TIE = 'Tie',
  PUSH = 'Push',
  POSTPONED = 'postponed'
}

export enum VENUE {
  HOME = 'Home',
  ROAD = 'Road'
}

interface BetData {
  bookmaker: string;
  result: RESULT;
  price: {
    open: number;
    current: number;
    close?: number;
    best: number;
  };
  point: {
    open: number;
    current: number;
    close?: number;
    best: number;
  };
  percent_money: number;
  percent_bets: number;
  total_bets: number;
}

export interface TeamGameData {
  id: string;
  name: string;
  venue: VENUE;
  result: RESULT;
  score: number;
  h2h?: BetData;
  spreads?: BetData;
  totals?: BetData;
  '1hh2h'?: BetData;
  '1hspreads'?: BetData;
  '1htotals'?: BetData;
  '1qh2h'?: BetData;
  '1qspreads'?: BetData;
  '1qtotals'?: BetData;
  '2qh2h'?: BetData;
  '2qspreads'?: BetData;
  '2qtotals'?: BetData;
  '3qh2h'?: BetData;
  '3qspreads'?: BetData;
  '3qtotals'?: BetData;
  '4qh2h'?: BetData;
  '4qspreads'?: BetData;
  '4qtotals'?: BetData;
  '1ph2h'?: BetData;
  '1pspreads'?: BetData;
  '1ptotals'?: BetData;
  '2ph2h'?: BetData;
  '2pspreads'?: BetData;
  '2ptotals'?: BetData;
  '3ph2h'?: BetData;
  '3pspreads'?: BetData;
  '3ptotals'?: BetData;
}

export interface GameData {
  awayTeam: string;
  homeTeam: string;
  id: string;
  startTime: Date;
  teams: {
    [teamId: string]: TeamGameData;
  };
}

export interface BetsData {
  id: string;
  startTime: Date;
  homeTeam: string;
  awayTeam: string;
  teams: {
    away_team: TeamBets;
    home_team: TeamBets;
  };
}

interface TeamBets {
  id: string;
  venue: VENUE;
  score: number;
  q1?: number;
  q2?: number;
  q3?: number;
  q4?: number;
  p1?: number;
  p2?: number;
  p3?: number;
  ot?: number;
  result: RESULT;
  bets: {
    bet: {
      [marketKey: string]: SimpleBetData;
    };
  };
}

interface SimpleBetData {
  value: number;
  result: RESULT;
}

export interface DataExplorerBetData {
  [id: string]: {
    hash: string;
    datetime: number;
    outcome_price_home: number;
    outcome_point_home: number;
    outcome_price_away: number;
    outcome_point_away: number;
  };
}

export interface DataExplorerScoreData {
  [id: string]: {
    hash: string;
    datetime: number;
    away_team_score: number;
    home_team_score: number;
    home_team_result: 'Win' | 'Loss' | 'Tie';
    away_team_result: 'Win' | 'Loss' | 'Tie';
  };
}

export interface DataExplorerGameData {
  id: string;
  sport_key: string;
  commence_time: string;
  home_team: string;
  away_team: string;
  bet_data: {
    h2h: DataExplorerBetData;
    spreads: DataExplorerBetData;
    totals: DataExplorerBetData;
  };
  scores: DataExplorerScoreData;
}
