import './Icon.scss';
import { IconProps } from '.';

export const FacebookIcon = (props: IconProps) => (
  <img
    src="/icons/facebook.png"
    alt="facebook logo"
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);

export const TwitterIcon = (props: IconProps) => (
  <img
    src="/icons/twitter.png"
    alt="twitter logo"
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);

export const InstaIcon = (props: IconProps) => (
  <img
    src="/icons/instagram.png"
    alt="instagram logo"
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);
