import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';

import { Calendar, ArrowRight } from 'react-bootstrap-icons';

import 'react-datepicker/dist/react-datepicker.css';
import './DateSelector.scss';

export interface DateRange {
  start: Date;
  end: Date;
}

export interface DateSelectorProps {
  selectedDateRange: DateRange;
  onSelectDateRange?: (selectedDate: DateRange) => void;
  hideHelpText?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const DateSelector = (props: DateSelectorProps) => {
  const {
    selectedDateRange,
    onSelectDateRange,
    hideHelpText,
    className,
    style
  } = props;

  const handleChangeDate = (type: 'start' | 'end', date: Date) => {
    onSelectDateRange &&
      onSelectDateRange({
        ...selectedDateRange,
        [type]: date
      });
  };

  const DatePickerCustomInput = forwardRef<HTMLButtonElement, any>(
    ({ value, onClick }, ref: React.ForwardedRef<HTMLButtonElement>) => {
      const today = new Date();
      const monthString = String(today.getMonth() + 1).padStart(2, '0');
      const dayString = String(today.getDate()).padStart(2, '0');
      const valueIsToday = `${monthString}/${dayString}` === value;

      return (
        <button className="date-picker" onClick={onClick} ref={ref}>
          {valueIsToday ? 'Today' : value}
          <Calendar />
        </button>
      );
    }
  );

  return (
    <div className={`date-selector ${className}`} style={style}>
      {!hideHelpText && (
        <div className="title-text mb-1">Select the Frequency</div>
      )}
      <div className="d-flex align-items-center">
        <DatePicker
          dateFormat="MM/dd"
          selected={selectedDateRange.start}
          onChange={(date) => {
            handleChangeDate('start', date || selectedDateRange.start);
          }}
          customInput={<DatePickerCustomInput />}
          filterDate={(date) =>
            date.getTime() < Date.now() &&
            date.getTime() <= selectedDateRange.end.getTime()
          }
          todayButton="Today"
        />
        <ArrowRight size={18} className="between-date-arrow" />
        <DatePicker
          dateFormat="MM/dd"
          selected={selectedDateRange.end}
          onChange={(date) => {
            handleChangeDate('end', date || selectedDateRange.end);
          }}
          customInput={<DatePickerCustomInput />}
          filterDate={(date) =>
            date.getTime() < Date.now() &&
            date.getTime() >= selectedDateRange.start.getTime()
          }
          todayButton="Today"
        />
      </div>
    </div>
  );
};
