import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  AwayTeamLoss,
  AwayTeamWin,
  AwayGameCurrent,
  DateTeamPicker,
  HomeTeamLoss,
  HomeTeamWin,
  HomeGameCurrent,
  DateSpreadPicker,
  Legend
} from '../../components/common';
import { OddsSelector } from '../../components/common/OddsSelector';
import { TeamSelector } from '../../components/common/TeamSelector';
import { Wrapper } from '../../components/common/Wrapper';
import { DateRange, DateSelector } from '../../components/common/DateSelector';
import {
  SpreadFilter,
  SpreadRange
} from '../../components/common/SpreadFilter';
import { GAME_ODDS, TeamData, VALID_SPORTS } from '../../models';
import {
  BetsData,
  GameData,
  GamePeriods,
  GENERAL_GAME_PERIODS,
  HOCKEY_GAME_PERIODS,
  SECONDARY_NAV,
  SUB_PAGE
} from '../../models/common';
import { Streaks } from './Streaks';
import '../../styles/page.scss';
import { LineMovement } from './LineMovement';
import { Tracker } from './Tracker';
import { TeamHistoricalResults } from './TeamHistoricalResults';
export * from './Data_Explorer';

export interface CommonPageProps {
  sport: VALID_SPORTS;
  getGamesQuery: UseQuery<
    QueryDefinition<
      { sportKey: string; league: string },
      any,
      never,
      GameData[],
      string
    >
  >;
  getAllTeamsQuery: UseQuery<
    QueryDefinition<any, any, never, TeamData[], string>
  >;
  getAllGamesQuery: UseQuery<
    QueryDefinition<
      {
        marketKey: string;
        sportKey: string;
        league: string;
        includePrices: boolean;
        endDate?: string;
      },
      any,
      never,
      GameData[],
      string
    >
  >;
  getGamesByTeamIdQuery: UseQuery<
    QueryDefinition<
      {
        teamId: string;
        marketKey: string;
        sportKey: string;
        league: string;
        includePrices: boolean;
      },
      any,
      never,
      GameData[],
      string
    >
  >;
  getBetsByTeamIdQuery: UseQuery<
    QueryDefinition<
      {
        teamId: string;
        marketKeys: string[];
        sportKey: string;
        league: string;
      },
      any,
      never,
      BetsData[],
      string
    >
  >;
}

export const mapSubPageToApiKey = (
  subPage: SUB_PAGE
): 'h2h' | 'spreads' | 'totals' => {
  switch (subPage) {
    case SUB_PAGE.TOTAL:
      return 'totals';
    case SUB_PAGE.SPREAD:
      return 'spreads';
    case SUB_PAGE.MONEYLINE:
    default:
      return 'h2h';
  }
};

export const mapSubPageAndTimeDivisionToApiKey = (
  subPage: SUB_PAGE,
  timeDivision: GamePeriods
) => {
  if (
    timeDivision === null ||
    timeDivision === GENERAL_GAME_PERIODS.FULL_TIME ||
    timeDivision === HOCKEY_GAME_PERIODS.FULL_TIME
  ) {
    return mapSubPageToApiKey(subPage);
  } else {
    switch (subPage) {
      case SUB_PAGE.TOTAL:
        if (timeDivision === GENERAL_GAME_PERIODS.FIRST_HALF) {
          return '1htotals';
        }
        if (timeDivision === GENERAL_GAME_PERIODS.FIRST_QUARTER) {
          return '1qtotals';
        }
        if (timeDivision === GENERAL_GAME_PERIODS.SECOND_QUARTER) {
          return '2qtotals';
        }
        if (timeDivision === GENERAL_GAME_PERIODS.THIRD_QUARTER) {
          return '3qtotals';
        }
        if (timeDivision === GENERAL_GAME_PERIODS.FOURTH_QUARTER) {
          return '4qtotals';
        }
        if (timeDivision === HOCKEY_GAME_PERIODS.FIRST_PERIOD) {
          return '1ptotals';
        }
        if (timeDivision === HOCKEY_GAME_PERIODS.SECOND_PERIOD) {
          return '2ptotals';
        }
        if (timeDivision === HOCKEY_GAME_PERIODS.THIRD_PERIOD) {
          return '3ptotals';
        }
        return '1htotals'; //TODO: removed this return and add else statement
      case SUB_PAGE.SPREAD:
        if (timeDivision === GENERAL_GAME_PERIODS.FIRST_HALF) {
          return '1hspreads';
        }
        if (timeDivision === GENERAL_GAME_PERIODS.FIRST_QUARTER) {
          return '1qspreads';
        }
        if (timeDivision === GENERAL_GAME_PERIODS.SECOND_QUARTER) {
          return '2qspreads';
        }
        if (timeDivision === GENERAL_GAME_PERIODS.THIRD_QUARTER) {
          return '3qspreads';
        }
        if (timeDivision === GENERAL_GAME_PERIODS.FOURTH_QUARTER) {
          return '4qspreads';
        }
        if (timeDivision === HOCKEY_GAME_PERIODS.FIRST_PERIOD) {
          return '1pspreads';
        }
        if (timeDivision === HOCKEY_GAME_PERIODS.SECOND_PERIOD) {
          return '2pspreads';
        }
        if (timeDivision === HOCKEY_GAME_PERIODS.THIRD_PERIOD) {
          return '3pspreads';
        }
        return '1hspreads'; //TODO: removed this return and add else statement
      case SUB_PAGE.MONEYLINE:
      default:
        if (timeDivision === GENERAL_GAME_PERIODS.FIRST_HALF) {
          return '1hh2h';
        }
        if (timeDivision === GENERAL_GAME_PERIODS.FIRST_QUARTER) {
          return '1qh2h';
        }
        if (timeDivision === GENERAL_GAME_PERIODS.SECOND_QUARTER) {
          return '2qh2h';
        }
        if (timeDivision === GENERAL_GAME_PERIODS.THIRD_QUARTER) {
          return '3qh2h';
        }
        if (timeDivision === GENERAL_GAME_PERIODS.FOURTH_QUARTER) {
          return '4qh2h';
        }
        if (timeDivision === HOCKEY_GAME_PERIODS.FIRST_PERIOD) {
          return '1ph2h';
        }
        if (timeDivision === HOCKEY_GAME_PERIODS.SECOND_PERIOD) {
          return '2ph2h';
        }
        if (timeDivision === HOCKEY_GAME_PERIODS.THIRD_PERIOD) {
          return '3ph2h';
        }
        return '1hh2h'; //TODO: removed this return and add else statement
    }
  }
};

export const mapSportToApiKey = (
  sport: VALID_SPORTS
): { sportKey: string; league: string } => {
  switch (sport) {
    case VALID_SPORTS.MLB:
      return {
        sportKey: 'baseball',
        league: 'mlb'
      };
    case VALID_SPORTS.NBA:
      return {
        sportKey: 'bsktbl',
        league: 'nba'
      };
    case VALID_SPORTS.NCAAB:
      return {
        sportKey: 'bsktbl',
        league: 'ncaa'
      };
    case VALID_SPORTS.NFL:
      return {
        sportKey: 'football',
        league: 'nfl'
      };
    case VALID_SPORTS.NCAAF:
      return {
        sportKey: 'football',
        league: 'fbs'
      };
    case VALID_SPORTS.NHL:
      return {
        sportKey: 'hockey',
        league: 'nhl'
      };
    default:
      return {
        sportKey: '',
        league: ''
      };
  }
};

export const CommonPage = (props: CommonPageProps) => {
  const {
    sport,
    getGamesQuery,
    getAllTeamsQuery,
    getAllGamesQuery,
    getGamesByTeamIdQuery,
    getBetsByTeamIdQuery
  } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [secondaryNavOption, setSecondaryNavOption] = useState<SECONDARY_NAV>(
    SECONDARY_NAV.STREAKS
  );
  const [subPage, setSubPage] = useState<SUB_PAGE | null>(null);
  const [currentOdds, setCurrentOdds] = useState<GAME_ODDS>(
    GAME_ODDS.FULL_TIME
  );
  const [dateRange, setDateRange] = useState<DateRange>({
    end: new Date(),
    start: new Date(Date.now() - 180 * 24 * 60 * 60 * 1000) // 180 days ago
  });
  const [spreadRange, setSpreadRange] = useState<SpreadRange>({
    low: 0,
    high: Number.POSITIVE_INFINITY
  });
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  // Hack to show calendar on mobile, since we can't independently set scrollX and scrollY in CSS :(
  const [calendarOpen, setCalendarOpen] = useState(false);

  const { data: allTeamsData } = getAllTeamsQuery(
    mapSportToApiKey(sport).league
  );

  useEffect(() => {
    const currentPath = pathname.split('/')[1] || null;
    if (
      currentPath &&
      Object.values(SECONDARY_NAV).includes(currentPath as SECONDARY_NAV) &&
      secondaryNavOption !== currentPath
    ) {
      setSecondaryNavOption(currentPath as SECONDARY_NAV);
    }
  }, [pathname]);

  useEffect(() => {
    // Set default sub nav on secondary nav swap
    setSubPage(
      secondaryNavOption === SECONDARY_NAV.PUBLIC
        ? SUB_PAGE.MONEYLINE
        : secondaryNavOption === SECONDARY_NAV.LINE_MOVEMENT
          ? SUB_PAGE.MONEYLINE
          : secondaryNavOption === SECONDARY_NAV.STREAKS
            ? SUB_PAGE.MONEYLINE
            : secondaryNavOption === SECONDARY_NAV.HISTORICAL_RESULTS
              ? SUB_PAGE.MONEYLINE
              : secondaryNavOption === SECONDARY_NAV.HIT_RATE
                ? SUB_PAGE.MONEYLINE
                : null
    );

    navigate(`./${secondaryNavOption}`);
  }, [secondaryNavOption]);

  return (
    <Wrapper
      secondaryNavOptions={[
        SECONDARY_NAV.STREAKS,
        SECONDARY_NAV.LINE_MOVEMENT,
        SECONDARY_NAV.HIT_RATE,
        SECONDARY_NAV.TEAM_HISTORICAL_RESULTS
      ]}
      selectedSecondaryNav={secondaryNavOption}
      onSelectSecondaryNav={(navOption) =>
        setSecondaryNavOption(navOption as SECONDARY_NAV)
      }
      primaryNavOptions={[
        {
          path: '/',
          type: 'home'
        },
        {
          path: '/mlb/',
          type: 'mlb',
          selected: sport === VALID_SPORTS.MLB
        },
        {
          path: '/nhl/',
          type: 'nhl',
          selected: sport === VALID_SPORTS.NHL
        },
        {
          path: '/nfl/',
          type: 'nfl',
          selected: sport === VALID_SPORTS.NFL
        },
        {
          path: '/ncaaf/',
          type: 'ncaaf',
          selected: sport === VALID_SPORTS.NCAAF
        },
        {
          path: '/nba/',
          type: 'nba',
          selected: sport === VALID_SPORTS.NBA
        },
        {
          path: '/ncaab/',
          type: 'ncaab',
          selected: sport === VALID_SPORTS.NCAAB
        }
      ]}
      sidenavContent={[
        {
          title: 'Team Selection',
          content: (
            <TeamSelector
              sport={sport}
              getAllTeamsQuery={getAllTeamsQuery}
              getGamesQuery={getGamesQuery}
            />
          )
        },
        {
          title: 'Game Odds',
          content: (
            <OddsSelector
              selectedOdds={currentOdds}
              onSelectOdds={setCurrentOdds}
            />
          )
        },
        {
          title: 'Date Range',
          content: (
            <DateSelector
              selectedDateRange={dateRange}
              onSelectDateRange={setDateRange}
            />
          )
        },
        {
          title: 'Spread Filter',
          content: (
            <SpreadFilter
              currentSpreadRange={spreadRange}
              onSelectSpread={setSpreadRange}
            />
          )
        }
      ]}
    >
      <div
        className="sub-page-bar"
        style={{ overflowX: calendarOpen ? 'visible' : 'scroll' }}
      >
        <div className="d-flex flex-row scrollable">
          <div>
            {secondaryNavOption === SECONDARY_NAV.STREAKS ? (
              <DateTeamPicker
                style={{ marginLeft: '1rem' }}
                onClickCalendar={setCalendarOpen}
              />
            ) : secondaryNavOption === SECONDARY_NAV.HIT_RATE ? (
              <DateSpreadPicker
                style={{ marginLeft: '1rem' }}
                onClickCalendar={setCalendarOpen}
                onDateChange={setSelectedDate}
              />
            ) : (
              <div className="sub-page-breadcrumb">
                {sport} &gt; {secondaryNavOption}
                {subPage ? ` > ${subPage}` : ''}
              </div>
            )}
            <div className="sub-page-selector">
              {Object.values(SUB_PAGE)
                .filter((subPageName) => {
                  switch (secondaryNavOption) {
                    case SECONDARY_NAV.PUBLIC:
                      return subPageName !== SUB_PAGE.ALL;
                    case SECONDARY_NAV.LINE_MOVEMENT:
                      return subPageName !== SUB_PAGE.ALL;
                    case SECONDARY_NAV.STREAKS:
                      return subPageName !== SUB_PAGE.ALL;
                    case SECONDARY_NAV.HISTORICAL_RESULTS:
                      return subPageName !== SUB_PAGE.ALL;
                    case SECONDARY_NAV.HIT_RATE:
                      return subPageName !== SUB_PAGE.ALL;
                    default:
                      // Hide subnav on all other pages
                      return false;
                  }
                })
                .map((subPageName) => (
                  <div
                    key={subPageName}
                    className={`sub-page-tab ${subPage === subPageName ? 'selected' : ''}`}
                    onClick={() => {
                      setSubPage(subPageName);
                    }}
                  >
                    {subPageName}
                  </div>
                ))}
            </div>
          </div>

          {secondaryNavOption === SECONDARY_NAV.STREAKS && (
            <div className="streaks-legend ms-3">
              <div className="row">
                <div className="col-4">
                  <HomeTeamWin height={'1rem'} /> Home Game Win
                </div>
                <div className="col-4">
                  <HomeTeamLoss height={'1rem'} /> Home Game Loss
                </div>
                <div className="col-4">
                  <AwayTeamWin height={'1rem'} number={1} /> Number of Push
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-4">
                  <AwayTeamWin height={'1rem'} /> Away Game Win
                </div>
                <div className="col-4">
                  <AwayTeamLoss height={'1rem'} /> Away Game Loss
                </div>
              </div>
            </div>
          )}
          {secondaryNavOption === SECONDARY_NAV.HISTORICAL_RESULTS && (
            <div className="streaks-legend ms-3">
              <div className="row">
                <div className="col-3">
                  <HomeTeamWin height={'1rem'} /> Home Game Win
                </div>
                <div className="col-3">
                  <HomeTeamLoss height={'1rem'} /> Home Game Loss
                </div>
                <div className="col-3">
                  <AwayTeamWin height={'1rem'} number={1} /> Number of Push
                </div>
                <div className="col-3">
                  <HomeGameCurrent height={'1rem'} /> Home Game Current
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-3">
                  <AwayTeamWin height={'1rem'} /> Away Game Win
                </div>
                <div className="col-3">
                  <AwayTeamLoss height={'1rem'} /> Away Game Loss
                </div>
                <div className="col-3">
                  <AwayGameCurrent height={'1rem'} /> Away Game Current
                </div>
              </div>
            </div>
          )}
          {secondaryNavOption === SECONDARY_NAV.HIT_RATE && <Legend />}
        </div>
      </div>
      <div className="d-flex flex-wrap" style={{ marginTop: '5rem' }}>
        {(() => {
          switch (secondaryNavOption) {
            case SECONDARY_NAV.STREAKS:
              return (
                <Streaks
                  sport={sport}
                  subPage={subPage || SUB_PAGE.MONEYLINE}
                  currentOdds={currentOdds}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  spreadRange={spreadRange}
                  allTeams={allTeamsData}
                  getGamesByTeamIdQuery={getGamesByTeamIdQuery}
                />
              );
            case SECONDARY_NAV.LINE_MOVEMENT:
              return (
                <LineMovement
                  sport={sport}
                  getAllGamesQuery={getAllGamesQuery}
                  subPage={subPage || SUB_PAGE.MONEYLINE}
                  currentOdds={currentOdds}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  spreadRange={spreadRange}
                  allTeams={allTeamsData}
                />
              );
            case SECONDARY_NAV.HIT_RATE:
              return (
                <Tracker
                  selectedDate={selectedDate}
                  getAllGames={getAllGamesQuery}
                  sport={sport}
                  subPage={subPage ?? SUB_PAGE.MONEYLINE}
                />
              );
            case SECONDARY_NAV.TEAM_HISTORICAL_RESULTS:
              return (
                allTeamsData && (
                  <TeamHistoricalResults
                    allTeams={allTeamsData}
                    getBetsByTeamId={getBetsByTeamIdQuery}
                    sport={sport}
                  />
                )
              );
            default:
              return <div>No Page Selected</div>;
          }
        })()}
      </div>
    </Wrapper>
  );
};
