import './Icon.scss';
import { IconProps } from '.';

export const ClipboardIcon = (props: IconProps) => (
  <img
    src="/icons/clipboard.png"
    alt="clipboard"
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);

export const DataIcon = (props: IconProps) => (
  <img
    src="/icons/data.png"
    alt="data"
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);

export const DisplayIcon = (props: IconProps) => (
  <img
    src="/icons/display.png"
    alt="display"
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);

export const FolderIcon = (props: IconProps) => (
  <img
    src="/icons/folder.png"
    alt="folder"
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);

export const HomeIcon = (props: IconProps) => (
  <img
    src="/icons/home.svg"
    alt="home"
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);

export const LocationIcon = (props: IconProps) => (
  <img
    src="/icons/location.png"
    alt="location"
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);

export const Mailicon = (props: IconProps) => (
  <img
    src="/icons/mail.png"
    alt="mail"
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);

export const MetadataIcon = (props: IconProps) => (
  <img
    src="/icons/metadata.png"
    alt="metadata"
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);

export const MobileIcon = (props: IconProps) => (
  <img
    src="/icons/mobile.png"
    alt="mobile"
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);

export const SecureIcon = (props: IconProps) => (
  <img
    src="/icons/secure.png"
    alt="secure"
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);

export const UpTrendGraphIcon = (props: IconProps) => (
  <img
    src="/icons/up_trend.png"
    alt="up trending graph"
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);

export const DownTrendGraphIcon = (props: IconProps) => (
  <img
    src="/icons/down_trend.png"
    alt="down trending graph"
    style={{
      height: props.height || '100%',
      width: props.width || '100%',
      objectFit: 'contain',
      ...props.style
    }}
  />
);
