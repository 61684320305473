export const toMonthDayString = (date: Date) => {
  const formatter = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric'
  });

  return formatter.format(date);
};

export const toDayString = (date: Date) =>
  new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(date);
