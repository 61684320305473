import './index.scss';

export const Legend = () => {
  return (
    <div className="legend-container">
      <div className="index">
        <div className="low" />
        35% or less
      </div>
      <div className="index">
        <div className="high" />
        70% or more
      </div>
    </div>
  );
};
