import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TeamData } from '../models/';

export const teamApi = createApi({
  reducerPath: 'teamApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.PUBLIC_URL + '/teams'
  }),
  endpoints: (builder) => ({
    // Team Queries
    getAllTeams: builder.query<TeamData[], string>({
      query: (league) => `./${league}.json`
    }),

    getTeamById: builder.query<TeamData, string>({
      query: (teamId) => `teams/${teamId}`
    })
  })
});

export const { useGetAllTeamsQuery, useGetTeamByIdQuery } = teamApi;
