export * from './Team';

export enum GAME_ODDS {
  FULL_TIME = 'Full Time',
  FIRST_HALF = 'First Half'
}

export enum VALID_SPORTS {
  NBA = 'NBA',
  NFL = 'NFL',
  NCAAF = 'NCAAF',
  NCAAB = 'NCAAB',
  NHL = 'NHL',
  MLB = 'MLB'
}
