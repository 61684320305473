import { GridColDef } from '@mui/x-data-grid';
import './index.scss';
import { SUB_PAGE } from '../../../models/common';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { PercentTag } from '..';

interface SpreadTableProps {
  data: Record<string, TableData>;
  subPage: SUB_PAGE;
}

export type TableData = {
  isHome: boolean;
  spread: number | null;
  mostRecentDate: Date;
  spreadRecord: {
    wins: number;
    losses: number;
    pushes: number;
  };
  homeSpreadRecord: {
    wins: number;
    losses: number;
    pushes: number;
  };
  awaySpreadRecord: {
    wins: number;
    losses: number;
    pushes: number;
  };
};

export const SpreadTable = (props: SpreadTableProps) => {
  const { data, subPage } = props;

  const columns: GridColDef[] = [
    {
      field: 'teamName',
      headerName: 'Team',
      width: 190
    },
    {
      field: 'spread',
      headerName: subPage,
      type: 'number',
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) =>
        subPage === SUB_PAGE.SPREAD && value > 0 ? `+${value}` : value
    },
    {
      field: 'location',
      headerName: 'Home/Away',
      width: 115,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'homeAwayRecord',
      headerName: 'Home/Away Record',
      width: 170,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      renderCell: ({ value }) => (
        <PercentTag
          wins={value.wins}
          losses={value.losses}
          draws={value.pushes}
        />
      ),
      sortComparator: (v1, v2) =>
        (100 * v1.wins) / (v1.wins + v1.losses + v1.pushes) -
        (100 * v2.wins) / (v2.wins + v2.losses + v2.pushes)
    },
    {
      field: 'spreadRecord',
      headerName: 'Season Record',
      renderCell: ({ value }) => (
        <PercentTag
          wins={value.wins}
          losses={value.losses}
          draws={value.pushes}
        />
      ),
      width: 170,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      sortComparator: (v1, v2) =>
        (100 * v1.wins) / (v1.wins + v1.losses + v1.pushes) -
        (100 * v2.wins) / (v2.wins + v2.losses + v2.pushes)
    }
  ];

  const rows = Object.entries(data).map(([teamName, teamData], index) => {
    const { isHome, spread, spreadRecord, homeSpreadRecord, awaySpreadRecord } =
      teamData;

    return {
      id: index,
      teamName,
      spread,
      location: isHome ? 'Home' : 'Away',
      homeAwayRecord: isHome ? homeSpreadRecord : awaySpreadRecord,
      spreadRecord
    };
  });

  return (
    <div className="datagrid-container">
      <DataGridPro
        rows={rows}
        columns={columns}
        className="table-container"
        pageSizeOptions={[rows.length]}
        hideFooter
      />
    </div>
  );
};
