import { useEffect } from 'react';

import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';

import {
  setTeams,
  selectAll,
  selectNone,
  toggleTeam,
  setGameDates
} from '../../../redux/teamsSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';

import { TeamData, VALID_SPORTS } from '../../../models/';
import { GameData } from '../../../models/common';
import { mapSportToApiKey } from '../../../pages/common';

import './TeamSelector.scss';

export interface TeamSelectorProps {
  sport: VALID_SPORTS;
  getAllTeamsQuery: UseQuery<
    QueryDefinition<any, any, never, TeamData[], string>
  >;
  getGamesQuery: UseQuery<QueryDefinition<any, any, never, GameData[], string>>;
}

export const TeamSelector = (props: TeamSelectorProps) => {
  const { sport, getAllTeamsQuery, getGamesQuery } = props;
  const dispatch = useAppDispatch();
  const selectedTeams = useAppSelector((state) => state.teams.teams);

  const { data: allTeamsData } = getAllTeamsQuery(
    mapSportToApiKey(sport).league
  );

  useEffect(() => {
    dispatch(setTeams(allTeamsData || []));
  }, [allTeamsData]);

  const { data: gamesData } = getGamesQuery(mapSportToApiKey(sport));

  useEffect(() => {
    if (gamesData) {
      dispatch(setGameDates(gamesData));
    }
  }, [gamesData]);

  const handleClickTeam = (teamId: string) => {
    dispatch(toggleTeam(teamId));
  };

  return (
    <div className="team-selector">
      <div className="d-flex justify-content-between ps-3 mb-2 mt-1">
        <div
          className="action-text"
          onClick={() => {
            dispatch(selectAll());
          }}
        >
          Select All
        </div>
        <div
          className="action-text"
          onClick={() => {
            dispatch(selectNone());
          }}
        >
          Select None
        </div>
      </div>

      <ul className="teams-list">
        {[...(allTeamsData || [])]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((team) => (
            <li
              key={team.id}
              onClick={() => handleClickTeam(team.id)}
              className={`team ${selectedTeams[team.id] ? 'selected' : ''}`}
            >
              {team.name}
            </li>
          ))}
      </ul>
    </div>
  );
};
