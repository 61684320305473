import { Link } from 'react-router-dom';
import {
  BaseballIcon,
  BasketballIcon,
  FootballIcon,
  HockeyIcon
} from '../Icon';
import { HomeIcon } from '../Icon/MiscIcon';

import './NavLinkIcon.scss';

export interface NavLinkIconProps {
  path: string;
  type: 'home' | 'mlb' | 'nhl' | 'nba' | 'nfl' | 'ncaaf' | 'ncaab';
  disableMargin?: boolean;
  selected?: boolean;
}

export const NavLinkIcon = (props: NavLinkIconProps) => {
  const { path, type, disableMargin, selected } = props;

  return (
    <Link to={path}>
      <div
        className="icon-nav-link-container"
        style={{
          marginBottom: disableMargin ? 0 : undefined,
          marginTop: disableMargin ? 0 : undefined
        }}
      >
        <div className="logo-container">
          {(() => {
            switch (type) {
              case 'home':
                return <HomeIcon />;
              case 'mlb':
                return <BaseballIcon />;
              case 'nhl':
                return <HockeyIcon />;
              case 'nba':
              case 'ncaab':
                return <BasketballIcon />;
              case 'nfl':
              case 'ncaaf':
                return <FootballIcon />;
            }
          })()}
        </div>
        <div className={`text-container ${selected ? 'selected' : ''}`}>
          {(() => {
            switch (type) {
              case 'home':
                return 'Home';
              case 'mlb':
                return 'MLB';
              case 'nhl':
                return 'NHL';
              case 'nfl':
                return 'NFL';
              case 'ncaaf':
                return 'NCAAF';
              case 'nba':
                return 'NBA';
              case 'ncaab':
                return 'NCAAB';
            }
          })()}
        </div>
      </div>
    </Link>
  );
};
