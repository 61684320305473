import { ArrowRight } from 'react-bootstrap-icons';

import 'react-datepicker/dist/react-datepicker.css';
import './SpreadFilter.scss';

export interface SpreadRange {
  low: number;
  high: number;
}

export interface SpreadFilterProps {
  currentSpreadRange: SpreadRange;
  onSelectSpread?: (selectedSpread: SpreadRange) => void;
}

export const SpreadFilter = (props: SpreadFilterProps) => {
  const { currentSpreadRange, onSelectSpread } = props;

  const handleChangeSpread = (type: 'low' | 'high', spread: number) => {
    onSelectSpread &&
      onSelectSpread({
        ...currentSpreadRange,
        [type]: spread
      });
  };

  return (
    <div className="spread-filter">
      <div className="d-flex align-items-center">
        <input
          type="text"
          className="spread-input"
          onChange={(evt) => {
            const nextVal = Number(evt.target.value);
            if (isNaN(nextVal)) {
              return;
            }
            handleChangeSpread('low', nextVal);
          }}
          value={currentSpreadRange.low}
        />
        <ArrowRight size={18} className="between-arrow" />
        <input
          type="text"
          className="spread-input"
          onChange={(evt) => {
            const nextVal = Number(evt.target.value);
            if (isNaN(nextVal)) {
              return;
            }
            handleChangeSpread('high', nextVal);
          }}
          value={currentSpreadRange.high}
        />
      </div>
    </div>
  );
};
