import React, { useEffect, useState } from 'react';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Card, LoadingOverlay } from '../../components/common';
import { DateRange, DateSelector } from '../../components/common/DateSelector';
import { SpreadRange } from '../../components/common/SpreadFilter';
import { GAME_ODDS, TeamData, VALID_SPORTS } from '../../models';
import { SUB_PAGE, GameData, VENUE } from '../../models/common';
import { mapSportToApiKey, mapSubPageToApiKey } from '.';
import '../../styles/page.scss';
import { selectAll } from '../../redux/teamsSlice';

const DAY_START_MS = new Date().setHours(0, 0, 0, 0);
const DAY_MS = 24 * 60 * 60 * 1000;

export interface LineMovementProps {
  sport: VALID_SPORTS;
  getAllGamesQuery: UseQuery<
    QueryDefinition<
      {
        marketKey: string;
        sportKey: string;
        league: string;
        includePrices: boolean;
      },
      any,
      never,
      GameData[],
      string
    >
  >;
  subPage: SUB_PAGE;
  currentOdds: GAME_ODDS;
  dateRange: DateRange;
  setDateRange: (range: DateRange) => void;
  spreadRange: SpreadRange;
  allTeams?: TeamData[];
}

export const LineMovement = (props: LineMovementProps) => {
  const {
    subPage,
    dateRange,
    setDateRange,
    spreadRange,
    sport,
    getAllGamesQuery
  } = props;

  const [filteredGameData, setFilteredGameData] = useState<GameData[]>([]);
  const [maxLineMovement, setMaxLineMovement] = useState(100);
  const selectedTeams = useAppSelector((state) => state.teams.teams);
  const dispatch = useAppDispatch();

  const {
    data: allGamesData,
    error: allGamesError,
    isLoading: allgamesLoading
  } = getAllGamesQuery({
    sportKey: mapSportToApiKey(sport).sportKey,
    league: mapSportToApiKey(sport).league,
    marketKey: mapSubPageToApiKey(subPage),
    includePrices: true
  });

  const useKey =
    subPage === SUB_PAGE.MONEYLINE
      ? 'price'
      : subPage === SUB_PAGE.TOTAL
        ? 'point'
        : sport === VALID_SPORTS.NBA || sport === VALID_SPORTS.NCAAB
          ? 'point'
          : 'price';

  useEffect(() => {
    dispatch(selectAll());

    // Stash the old date range - when we navigate away, we want to reset to that value
    localStorage.setItem('prevDateRange', JSON.stringify(dateRange));

    // For this page, we want the default date range to be the last 3 days
    setDateRange({
      start: new Date(DAY_START_MS - 3 * DAY_MS),
      end: new Date(DAY_START_MS + DAY_MS - 1)
    });
  }, []);

  useEffect(() => {
    if (!allGamesData?.length) {
      return;
    }

    const newData = allGamesData
      .filter((gameData) => {
        return (
          // Filter by sidebar date
          new Date(gameData.startTime).getTime() >= dateRange.start.getTime() &&
          new Date(gameData.startTime).getTime() <= dateRange.end.getTime() &&
          // Filter by sidebar team selector
          Object.values(gameData.teams).reduce((acc, team) => {
            return acc || selectedTeams[team.id];
          }, false) &&
          true
          // // TODO: Filter by sidebar spread selector
          // Object.values(gameData.teams).reduce((acc, team) => {
          //   const minOdds = Math.min(
          //     team[mapSubPageToApiKey(subPage)].best,
          //     team[mapSubPageToApiKey(subPage)].open,
          //     team[mapSubPageToApiKey(subPage)].close
          //   )
          //   const maxOdds = Math.max(
          //     team[mapSubPageToApiKey(subPage)].best,
          //     team[mapSubPageToApiKey(subPage)].open,
          //     team[mapSubPageToApiKey(subPage)].close
          //   )

          //   return minOdds >= spreadRange.low && maxOdds <= spreadRange.high
          // }, true)
        );
      })
      .sort((a, b) => {
        // Sort by date descending
        return (
          new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
        );
      });

    const updatedMaxLineMovement = newData.reduce((acc, game) => {
      const maxOfTeams = Object.values(game.teams).reduce((acc, team) => {
        if (!team[mapSubPageToApiKey(subPage)]) {
          return acc;
        }

        const teamData = team[mapSubPageToApiKey(subPage)]?.[useKey];

        const lineMovementTest: number = Math.abs(
          teamData
            ? useKey === 'point' || teamData.current * teamData.open > 0
              ? teamData.current - teamData.open
              : -200 * Math.sign(teamData.current) +
                (teamData.current - teamData.open)
            : 0
        );

        if (!isNaN(lineMovementTest)) {
          return Math.max(acc, lineMovementTest);
        }

        return acc;
      }, 0);

      return Math.max(acc, maxOfTeams);
    }, 0);

    setMaxLineMovement(updatedMaxLineMovement);
    setFilteredGameData(newData);
  }, [allGamesData, dateRange, selectedTeams, spreadRange, subPage]);

  const generateLineMovementChart = (venue: VENUE, date: Date) => (
    <Card classList="line-movement-container container-fluid px-3 pb-3">
      <LoadingOverlay
        loading={allgamesLoading}
        error={!!allGamesError}
        noData={false}
      >
        {filteredGameData
          .filter((game) => {
            const gameStartTime = new Date(game.startTime).getTime();
            return (
              gameStartTime >= date.getTime() &&
              gameStartTime < date.getTime() + DAY_MS
            );
          })
          .map((game) => {
            const teamData = Object.values(game.teams).find(
              (team) => team.venue === venue
            )?.[mapSubPageToApiKey(subPage)]?.[useKey];
            const lineMovement = teamData
              ? useKey === 'point' || teamData.current * teamData.open > 0
                ? teamData.current - teamData.open
                : -200 * Math.sign(teamData.current) +
                  (teamData.current - teamData.open)
              : 0;
            const isPos = lineMovement >= 0;

            return (
              <div
                className="d-flex align-items-center mb-2 line"
                style={{ justifyContent: isPos ? 'flex-start' : 'flex-end' }}
                key={game.id}
              >
                {!isPos && (
                  <div className="me-1">
                    {!isNaN(lineMovement) ? lineMovement : ''}
                  </div>
                )}
                <div
                  style={{
                    marginLeft: isPos ? '50%' : 0,
                    marginRight: isPos ? 0 : '50%',
                    borderRadius: isPos
                      ? '0 0.5rem 0.5rem 0'
                      : '0.5rem 0 0 0.5rem',
                    backgroundColor: isPos ? '#7EAB46' : '#C33636',
                    width: `${50 * Math.abs(lineMovement / maxLineMovement)}%`
                  }}
                ></div>
                {isPos && <div className="ms-1">{lineMovement}</div>}
              </div>
            );
          })}
      </LoadingOverlay>
    </Card>
  );

  const generateStatsTable = (venue: VENUE, date: Date) => (
    <Card classList="stats-container container-fluid px-3 pb-3">
      <LoadingOverlay
        loading={allgamesLoading}
        error={!!allGamesError}
        noData={!filteredGameData.length}
      >
        <div className="header-row row">
          {venue === VENUE.ROAD && <div className="col-6 right">Away Team</div>}
          <div className="col-3 center">
            Opening {useKey === 'price' ? 'Price' : 'Point'}
          </div>
          <div className="col-3 center">
            Current {useKey === 'price' ? 'Price' : 'Point'}
          </div>
          {venue === VENUE.HOME && <div className="col-6">Home Team</div>}
        </div>
        {filteredGameData
          .filter((game) => {
            const gameStartTime = new Date(game.startTime).getTime();
            return (
              gameStartTime >= date.getTime() &&
              gameStartTime < date.getTime() + DAY_MS
            );
          })
          .map((game) => {
            const team = Object.values(game.teams).find(
              (team) => team.venue === venue
            );
            const teamData = team?.[mapSubPageToApiKey(subPage)]?.[useKey];

            return (
              <div key={game.id} className="row mb-2 game-row">
                {venue === VENUE.ROAD && (
                  <div className="col-6 right">{team?.id}</div>
                )}
                <div className="col-3 center">{teamData?.open}</div>
                <div
                  className="col-3 center"
                  style={{
                    color: teamData?.close ? 'rgb(126, 171, 70)' : undefined
                  }}
                >
                  {teamData?.current}
                </div>
                {venue === VENUE.HOME && (
                  <div className="col-6">{team?.id}</div>
                )}
              </div>
            );
          })}
      </LoadingOverlay>
    </Card>
  );

  const dateObject = allgamesLoading
    ? { dToday: new Date(new Date().setHours(0, 0, 0, 0)) }
    : filteredGameData.reduce(
        (acc, game) => {
          const gameDayTimestamp = new Date(game.startTime).setHours(
            0,
            0,
            0,
            0
          );

          acc['d' + String(gameDayTimestamp)] = new Date(gameDayTimestamp);
          return acc;
        },
        {} as { [idx: string]: Date }
      );

  const mapDateToTitleText = (date: Date) => {
    if (
      date.getTime() >= DAY_START_MS &&
      date.getTime() < DAY_START_MS + DAY_MS
    ) {
      return "Today's Line Movement";
    } else if (
      date.getTime() >= DAY_START_MS + DAY_MS &&
      date.getTime() < DAY_START_MS + 2 * DAY_MS
    ) {
      return "Tomorrow's Line Movement";
    } else if (
      date.getTime() >= DAY_START_MS - DAY_MS &&
      date.getTime() < DAY_START_MS
    ) {
      return "Yesterday's Line Movement";
    } else {
      return `${date.toLocaleString('en-us', { weekday: 'long' })}'s Line Movement`;
    }
  };

  return (
    <div className="line-movement-page">
      <DateSelector
        selectedDateRange={dateRange}
        onSelectDateRange={setDateRange}
        hideHelpText={true}
        className="mx-4 ps-2 my-2"
      />
      {Object.keys(dateObject).length === 0 ? (
        <div className="no-games-text">
          No line movement results available for current parameters.
          <br />
          Try adjusting the date range, or selecting teams in the sidebar
        </div>
      ) : (
        Object.values(dateObject).map((currentDate) => (
          <React.Fragment key={currentDate.getTime()}>
            <div className="row m-3 ps-2 line-movement-header">
              <div className="col-1 current-date">
                {currentDate.toLocaleDateString('en-US')}
              </div>
              <div className="col-11">{mapDateToTitleText(currentDate)}</div>
            </div>

            <div className="row mx-3 gx-4">
              {/* Home Team: Line Movement */}
              <div className="col-2">
                {generateLineMovementChart(VENUE.ROAD, currentDate)}
              </div>

              {/* Home Team: Stats */}
              <div className="col-4">
                {generateStatsTable(VENUE.ROAD, currentDate)}
              </div>

              {/* Away Team: Line Movement */}
              <div className="col-4">
                {generateStatsTable(VENUE.HOME, currentDate)}
              </div>

              {/* Away Team: Stats */}
              <div className="col-2">
                {generateLineMovementChart(VENUE.HOME, currentDate)}
              </div>
            </div>
          </React.Fragment>
        ))
      )}
    </div>
  );
};
